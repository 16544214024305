import type { CartesianPose } from '@sb/geometry';
import { distanceBetweenPoses } from '@sb/geometry';
import { makeNamespacedLog } from '@sb/log';
import type { ArmTarget } from '@sb/motion-planning';
import type { RoutineContext } from '@sb/routine-runner/RoutineContext';
import type { StatefulEventEmitter } from '@sb/utilities';

const log = makeNamespacedLog('WaypointReachedTracker');

// Distance threshold to determine if a waypoint has been reached
const WAYPOINT_REACHED_THRESHOLD_DIST = 0.1;

// Interval to check if a waypoint has been reached
const WAYPOINT_REACHED_CHECK_INTERVAL_MS = 100;

/**
 * This class is a helper class for RoutineContext to track if a waypoint has
 * been reached based on the current pose and the target list.  This is to solve
 * the issue when we pause and resume during a motion with multiple waypoints.
 * This class is separated to keep the logic separate from the RoutineContext class.
 *
 * This class works by checking the current pose against first target in the list.
 * Once the distance is below a threshold, the target is considered visited, and
 * the next target is checked.  This continues until all targets are visited.
 */
export class WaypointReachedTracker {
  private targets: ArmTarget[];

  private events: StatefulEventEmitter<any>;

  private routineContext: RoutineContext;

  private nextTargetIndex: number = 0;

  private nextTargetPose: CartesianPose | null = null;

  private nextTargetPoseDistThreshold: number = WAYPOINT_REACHED_THRESHOLD_DIST;

  private pauseListener: () => void;

  private resumeListener: () => void;

  private checkWaypointReachedInterval: ReturnType<typeof setInterval> | null =
    null;

  private isInitialized: boolean = false;

  private initializePromise: Promise<void>;

  constructor({
    targets,
    events,
    routineContext,
  }: {
    targets: ArmTarget[];
    events: StatefulEventEmitter<any>;
    routineContext: RoutineContext;
  }) {
    this.targets = targets;
    this.events = events;
    this.routineContext = routineContext;

    this.pauseListener = this.events.on('pause', () => {
      this.clearCheckWaypointReachedInterval();
    });

    this.resumeListener = this.events.on('resume', () => {
      this.setCheckWaypointReachedInterval();
    });

    this.initializePromise = this.init();
  }

  private async init() {
    try {
      await this.updateNextTargetPose();
      this.isInitialized = true;
    } catch (error) {
      log.error(
        'init.failed',
        'Failed to initialize WaypointReachedTracker:',
        error,
      );

      throw error;
    }
  }

  public async waitForInitialization() {
    await this.initializePromise;
  }

  public destroy() {
    this.clearCheckWaypointReachedInterval();

    this.pauseListener();
    this.resumeListener();
  }

  /**
   * Sets interval to call the checkWaypointReached function
   */
  public setCheckWaypointReachedInterval() {
    if (this.nextTargetIndex >= this.targets.length) {
      log.info(
        `uncategorized`,
        'All targets reached, not setting check waypoint reached interval',
      );

      return;
    }

    if (!this.isInitialized) {
      log.warn(
        `uncategorized`,
        'Attempted to set interval before initialization complete',
      );

      return;
    }

    log.info(
      `uncategorized`,
      `Setting check waypoint reached for ${this.targets.length} points with interval ${WAYPOINT_REACHED_CHECK_INTERVAL_MS}`,
    );

    this.clearCheckWaypointReachedInterval();

    // if we are using waypoints, run the check to see if we have reached a waypoint
    this.checkWaypointReachedInterval = setInterval(async () => {
      await this.checkWaypointReached();
    }, WAYPOINT_REACHED_CHECK_INTERVAL_MS);
  }

  /**
   * Clears the checkWaypointReached interval
   */
  public clearCheckWaypointReachedInterval() {
    if (!this.checkWaypointReachedInterval) {
      return;
    }

    log.info(`uncategorized`, 'Clearing check waypoint reached interval');
    clearInterval(this.checkWaypointReachedInterval);
    this.checkWaypointReachedInterval = null;
  }

  public isChecking(): boolean {
    return this.checkWaypointReachedInterval !== null;
  }

  /**
   * Return the list of targets that have not been reached yet.  We do simplier
   * approach of just return the targets from our target list, instead
   * of actually comparing the passed target list to the our own target list.
   * So this could possibly break if getUpdatedTargets is using a different list
   */
  public getUpdatedTargets(_: ArmTarget[]): ArmTarget[] {
    if (!this.isInitialized) {
      return this.targets;
    }

    log.info(
      `uncategorized`,
      `getUpdatedTargets ${this.nextTargetIndex + 1} / ${this.targets.length} `,
    );

    return this.targets.slice(
      Math.min(this.targets.length - 1, this.nextTargetIndex),
    );
  }

  private getCurrentPose() {
    return this.routineContext.getRoutineRunnerState().kinematicState
      .tooltipPoint;
  }

  private async getTargetPose(target: ArmTarget) {
    return await this.routineContext.getTargetPose(target);
  }

  /**
   * Check to see if we have reached the next waypoint.
   */
  private async checkWaypointReached() {
    if (this.nextTargetIndex >= this.targets.length) {
      this.clearCheckWaypointReachedInterval();

      return;
    }

    const dist = distanceBetweenPoses(
      this.getCurrentPose(),
      this.nextTargetPose as CartesianPose,
    );

    if (dist <= this.nextTargetPoseDistThreshold) {
      // remove the first target.  When the first routine is resumed, it will
      // replay from the next target, since we have removed the first one
      log.info(
        `uncategorized`,
        `Reached waypoint index: ${this.nextTargetIndex} target length: ${this.targets.length}`,
      );

      this.nextTargetIndex += 1;
      await this.updateNextTargetPose();
    }
  }

  private async updateNextTargetPose() {
    if (this.nextTargetIndex < this.targets.length) {
      const nextTarget = this.targets[this.nextTargetIndex];

      this.nextTargetPose = await this.getTargetPose(nextTarget);

      this.nextTargetPoseDistThreshold = Math.max(
        nextTarget.blendRadius ?? 0,
        WAYPOINT_REACHED_THRESHOLD_DIST,
      );
    }
  }
}
